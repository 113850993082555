import { createSlice } from '@reduxjs/toolkit'
import { TypeRootState } from '../../store/store'

export interface IServiceState {
    barbershop: number
    barberLVL: number
}
export interface IServiceObjState {
    service: IServiceState
}

export const barberShopOptions = {
    BARBERSHOP_1: 1,
    BARBERSHOP_2: 2,
};

export const barberLVLOptions = {
    BARBER_LVL_ANY: 0,
    BARBER_LVL_BARBER: 1,
    BARBER_LVL_BARBER_PRO: 2,
    BARBER_LVL_BARBER_TRAINEE: 3,
    BARBER_LVL_OWNER: 4
};

const initialState: IServiceObjState = {
    service: {
        barbershop: barberShopOptions.BARBERSHOP_1,
        barberLVL: barberLVLOptions.BARBER_LVL_BARBER,
    }
}

const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        barbershopChange(state, action) {
            state.service.barbershop = action.payload.barbershop
        },
        barberLVLChange(state, action) {
            state.service.barberLVL = action.payload.barberLVL
        },
    },
})
export const {
    barbershopChange,
    barberLVLChange,
} = serviceSlice.actions

export const selectAllService = (state: TypeRootState) => state.service
export default serviceSlice.reducer